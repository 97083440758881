import { type IconProps, Icon } from './icon.js';

export function Radio(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Radio" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M16.8632 16.9545c-.1908.1323-.4555.087-.5972-.0929-.1564-.1986-.1017-.4809.1079-.6284 1.775-1.2488 2.9261-3.2558 2.9261-5.5189 0-3.78711-3.2234-6.85716-7.2-6.85716-3.97661 0-7.2 3.07005-7.2 6.85716 0 2.1707 1.05901 4.1058 2.71188 5.3623.20687.1572.24788.4475.07646.6395-.15019.1683-.41136.2004-.59359.0639C5.21017 15.3675 4 13.1755 4 10.7143 4 6.4538 7.62637 3 12.1 3c4.4736 0 8.1 3.4538 8.1 7.7143 0 2.565-1.3144 4.8376-3.3368 6.2402Z" />
        <path d="M15.5392 14.6793c-.3475.2737-.8531.1761-1.1236-.1674-.3219-.4088-.1766-.9929.2063-1.3509.6654-.6222 1.0781-1.4885 1.0781-2.4467 0-1.89356-1.6119-3.42859-3.6-3.42859S8.5 8.82074 8.5 10.7143c0 .9305.38923 1.7744 1.02105 2.3921.3835.375.50985.985.15389 1.3839-.28212.3161-.77198.3896-1.10172.1185C7.42608 13.6658 6.7 12.2708 6.7 10.7143c0-2.84033 2.41787-5.14287 5.4-5.14287 2.9821 0 5.4 2.30254 5.4 5.14287 0 1.5957-.7632 3.0217-1.9608 3.965Z" />
        <path d="M13.9001 10.7143c0 .7988-.5737 1.47-1.35 1.6603v3.4825h.1519c.132 0 .2573.0553.3428.1511l3.825 4.2857c.2485.2783.0407.7061-.3428.7061H7.67316c-.38359 0-.5913-.4278-.34288-.7061l3.82502-4.2857c.0855-.0958.2108-.1511.3429-.1511h.1519v-3.4825c-.7764-.1903-1.35-.8615-1.35-1.6603 0-.94679.8058-1.7143 1.8-1.7143.9941 0 1.8.76751 1.8 1.7143Z" />
      </svg>
    </Icon>
  );
}
